<template>
  <div>
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
      class="app-login-form pa-6"
      @focus="formFocus"
    >
      <v-text-field
        :rules="emailRules"
        v-model="email"
        label="Email"
        required
        class="mt-8"
        @focus="formFocus"
      ></v-text-field>
      <v-text-field
        :type="showPassword ? 'text' : 'password'"
        :rules="passwordRules"
        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append="toggleShowPassword"
        v-model="password"
        label="Password"
        required
        class="mt-12"
        @focus="formFocus"
        autocomplete="on"
      ></v-text-field>
      <GoogleRecaptcha
        v-if="this.activateRecaptcha"
        :on-verify="captchaOnVerify"
        :on-expired="captchaOnExpired"
        ref="recaptcha"
      ></GoogleRecaptcha>
      <div class="app-login-form__submit mt-8">
        <v-row align="center" class="flex-column">
          <v-col cols="12" sm="10" md="8">
            <app-button
              :disabled="!enableSignInButton"
              ref="buttonLogin"
              block
              :on-click="accountSignIn"
              :loading="signingIn"
            >
              Login
            </app-button>
            <!-- <v-btn
              :disabled="!enableSignInButton"
              ref="buttonLogin"
              class="btn__primary"
              block
              x-large
              @click="accountSignIn"
              :loading="signingIn"
            >
              Login
            </v-btn> -->
            <router-link to="/password/reset/request/form">
              <v-btn text plain class="app-login-form__forgotpassword my-4">
                Forgot Password?
                <v-icon medium color="#E44475">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </router-link>
            <app-button
              ref="buttonSignUp"
              block
              outlined
              class="app-login-form__signup"
              :on-click="accountSignIn"
              to="/register"
            >
              Sign Up
            </app-button>
            <app-button
              ref="buttonSignUp"
              block
              outlined
              class="app-login-form__signup mt-4"
              :on-click="loginWithMicrosoft"
            >
              Login with Microsoft
            </app-button>
            <!-- <router-link to="/register">
              <v-btn
                tile
                x-large
                color="primary"
                outlined
                class="app-login-form__signup mt-4"
                ><span class="black--text">Sign Up</span>
              </v-btn>
            </router-link> -->
          </v-col>
        </v-row>
      </div>
    </v-form>
    <div class="app-login-form__support mt-8 text-center">
      <v-btn text plain href="mailto:smrastrategyandcommunications@t-mobile.com">
        Support
        <v-icon medium color="#E44475">
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <ChangePasswordForm :open.sync='changePasswordDialog' :user='changePasswordUser' @handleSuccess='handleSignInSuccess($event)' />
    <v-snackbar
      top
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import GoogleRecaptcha from '@/components/GoogleRecaptcha.vue';
import { mapActions, mapGetters } from 'vuex';
import AppButton from '../AppButton.vue';
import ChangePasswordForm from '@/components/Account/ChangePasswordForm.vue';
import { Auth } from 'aws-amplify';

export default {
  name: 'LoginForm',
  components: {
    GoogleRecaptcha,
    AppButton,
    ChangePasswordForm
  },
  props: {},
  data() {
    return {
      activateRecaptcha: false,
      signingIn: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      snackbarTimeout: 3500,
      captchaVerfied: false,
      captchaToken: null,
      disableSignInButton: false,
      valid: true,
      email: '',
      password: '',
      showPassword: false,
      passwordRules: [v => !!v || 'Password is required'],
      emailRules: [
        v =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Not a valid email'
      ],
      changePasswordDialog: false,
      changePasswordUser: null
    };
  },
  computed: {
    ...mapGetters({
      AUTH_MODULE_STATE: `account/AUTH_MODULE_STATE`,
      AUTH_ERROR_MESSAGE: `account/AUTH_ERROR_MESSAGE`,
      USER_GROUPS: `account/USER_GROUPS`
    }),
    enableSignInButton: function() {
      return this.valid && this.captchaToken && !this.disableSignInButton;
    },
    redirectURL: function() {
      let redirect = this.$route.query.redirectURL || this.dashboardURL();
      let redirectQuery = this.$route.query.query
        ? JSON.parse(this.$route.query.query)
        : '';
      if (redirectQuery !== '') {
        let urlEncodedQueryObject = Object.keys(redirectQuery).map((x, i) => {
          let firstChar = '?';
          if (i !== 0) {
            firstChar = '&';
          }
          return `${firstChar}${x}=${encodeURIComponent(redirectQuery[x])}`;
        });
        return `${redirect}${urlEncodedQueryObject}`;
      }
      return this.$route.query.redirectURL || this.dashboardURL();
    }
  },
  methods: {
    ...mapActions({
      SIGN_IN: 'account/ACCOUNT_SIGN_IN',
      updateInfo: `account/UPDATE_USER_INFO`
    }),
    captchaOnVerify(token) {
      this.captchaToken = token;
      this.captchaVerfied = true;
      return;
    },
    captchaOnExpired() {
      this.captchaVerfied = false;
      this.captchaToken = null;
      return;
    },
    accountSignIn() {
      this.disableSignInButton = true;
      const signInParams = {
        username: this.email,
        password: this.password,
        attributes: {
          email: this.email
        },
        validationData: {
          token: this.captchaToken
        }
      };
      this.signingIn = true;
      this.SIGN_IN(signInParams)
        .then(response => {
          if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.changePasswordDialog = true;
            this.changePasswordUser = response;
            return;
          }

          this.handleSignInSuccess(response);
        })
        .catch();
    },
    processSignInResponse(response) {
      // if a user session is returned, store the session and redirect to the dashboard
      if (response.signInUserSession !== undefined) {
        this.updateInfo()
          .then(() => {
            this.$router.push(this.redirectURL);
          })
          .catch(err => {
            this.toggleSnackbar(err, 'error');
          });
        // localStorage.setItem('userAttributes', JSON.stringify(response));
        // we're not showing a success toast per katie, simply going to the dashboard
        // this.toggleSnackbar('Login successful!', 'success');
      } else {
        this.captchaVerfied = false;
        this.captchaToken = null;
        this.$refs.recaptcha.reset();
        this.disableSignInButton = false;
        if (this.AUTH_ERROR_MESSAGE !== null) {
          this.toggleSnackbar(this.AUTH_ERROR_MESSAGE, 'error');
        }
      }
    },
    toggleSnackbar(message, type) {
      this.snackbarText = message;
      this.snackbarColor = type == 'error' ? '#f03710' : '#0da149';
      this.snackbar = true;
    },
    validate() {
      this.$refs.form.validate();
    },
    formFocus() {
      if (!this.activateRecaptcha) {
        this.activateRecaptcha = true;
      }
    },
    dashboardURL() {
      let userGroups = this.USER_GROUPS;
      if (userGroups.includes('Admin')) {
        return '/dashboard';
      } else if (userGroups.includes('Legal')) {
        return '/legaldashboard';
      } else if (userGroups.includes('SLTranslator')) {
        return '/translationdashboard';
      } else if (userGroups.includes('Ambassador')) {
        return '/eventstaffdashboard';
      } else {
        return '/dashboard';
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    handleSignInSuccess(response) {
      this.processSignInResponse(response);
      this.signingIn = false;
    },
    async loginWithMicrosoft() {
      await Auth.federatedSignIn({ customProvider: 'DEVTMobileEngagementHub' });
    },
  },
  created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  }
};
</script>

<style scoped>
.app-login-form {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-login-form a {
  text-decoration: none;
}
.app-login-form__submit {
  text-align: center;
}
.app-login-form__signup {
  width: 80%;
}
::v-deep .app-login-form__forgotpassword.v-btn--plain .v-btn__content,
::v-deep .app-login-form__support .v-btn__content {
  opacity: 1 !important;
}
.v-btn--outlined {
  border-width: 2px;
}
.app-login-form__submit .v-btn,
.app-login-form__support .v-btn {
  font-weight: 700;
}
</style>
